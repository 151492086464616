import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoInstagramPng, LazySvgoContact, LazySvgoInstagramOpacity, LazySvgoInstagram, LazySvgoLinkedinPng, LazySvgoLinkedin, LazySvgoMenuBurger, LazySvgoTest, LazySvgoTiktok } from '#components'
const lazyGlobalComponents = [
  ["SvgoInstagramPng", LazySvgoInstagramPng],
["SvgoContact", LazySvgoContact],
["SvgoInstagramOpacity", LazySvgoInstagramOpacity],
["SvgoInstagram", LazySvgoInstagram],
["SvgoLinkedinPng", LazySvgoLinkedinPng],
["SvgoLinkedin", LazySvgoLinkedin],
["SvgoMenuBurger", LazySvgoMenuBurger],
["SvgoTest", LazySvgoTest],
["SvgoTiktok", LazySvgoTiktok],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
