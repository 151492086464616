import revive_payload_client_P2bvKt8sGD from "/vercel/path0/node_modules/.pnpm/nuxt@3.10.2_rollup@4.12.0_vite@5.1.3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_jmzUkyVpDq from "/vercel/path0/node_modules/.pnpm/nuxt@3.10.2_rollup@4.12.0_vite@5.1.3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_N1rG6d0tfc from "/vercel/path0/node_modules/.pnpm/nuxt@3.10.2_rollup@4.12.0_vite@5.1.3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_u1uHUI0mJt from "/vercel/path0/node_modules/.pnpm/nuxt@3.10.2_rollup@4.12.0_vite@5.1.3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_l6V6Z1sMU4 from "/vercel/path0/node_modules/.pnpm/nuxt@3.10.2_rollup@4.12.0_vite@5.1.3/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_Axyfd8LY60 from "/vercel/path0/node_modules/.pnpm/nuxt@3.10.2_rollup@4.12.0_vite@5.1.3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_yqVZSRC7af from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.1.1_rollup@4.12.0_vue@3.4.19/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_ezug42etgr from "/vercel/path0/node_modules/.pnpm/@nuxt+image@1.3.0_rollup@4.12.0/node_modules/@nuxt/image/dist/runtime/plugin.mjs";
import chunk_reload_client_yq2Xh9hMAH from "/vercel/path0/node_modules/.pnpm/nuxt@3.10.2_rollup@4.12.0_vite@5.1.3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import hover_directive_At8g6KzWvE from "/plugins/hover-directive.js";
import cursor_state_IlpYgpzF9z from "/plugins/cursor-state.js";
import clarity_plugin_QwlOEPlX8y from "/plugins/clarity-plugin.js";
import gtag_client_h5Qz5d3asB from "/plugins/gtag.client.js";
import axeptio_kqwAdnYpJz from "/vercel/path0/plugins/axeptio.js";
import clarity_plugin_k7tv5rUjPe from "/vercel/path0/plugins/clarity-plugin.js";
import cursor_state_HIoVQqCLhS from "/vercel/path0/plugins/cursor-state.js";
import gsap_client_Z6XKCRtOCT from "/vercel/path0/plugins/gsap.client.js";
import gtag_client_R70iZRv6jK from "/vercel/path0/plugins/gtag.client.js";
import hover_directive_3IxHn9Lif2 from "/vercel/path0/plugins/hover-directive.js";
export default [
  revive_payload_client_P2bvKt8sGD,
  unhead_jmzUkyVpDq,
  router_N1rG6d0tfc,
  payload_client_u1uHUI0mJt,
  check_outdated_build_client_l6V6Z1sMU4,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Axyfd8LY60,
  i18n_yqVZSRC7af,
  plugin_ezug42etgr,
  chunk_reload_client_yq2Xh9hMAH,
  hover_directive_At8g6KzWvE,
  cursor_state_IlpYgpzF9z,
  clarity_plugin_QwlOEPlX8y,
  gtag_client_h5Qz5d3asB,
  axeptio_kqwAdnYpJz,
  clarity_plugin_k7tv5rUjPe,
  cursor_state_HIoVQqCLhS,
  gsap_client_Z6XKCRtOCT,
  gtag_client_R70iZRv6jK,
  hover_directive_3IxHn9Lif2
]