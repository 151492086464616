import { default as aboutcggDDldG7iMeta } from "/vercel/path0/pages/about.vue?macro=true";
import { default as countdownvtqFImsxdOMeta } from "/vercel/path0/pages/countdown.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as mentions_45legalesaI7UrXH82KMeta } from "/vercel/path0/pages/mentions-legales.vue?macro=true";
import { default as testlK5QpQxp8eMeta } from "/vercel/path0/pages/test.vue?macro=true";
import { default as workssRZImgF9lMeta } from "/vercel/path0/pages/work.vue?macro=true";
export default [
  {
    name: aboutcggDDldG7iMeta?.name ?? "about",
    path: aboutcggDDldG7iMeta?.path ?? "/about",
    meta: aboutcggDDldG7iMeta || {},
    alias: aboutcggDDldG7iMeta?.alias || [],
    redirect: aboutcggDDldG7iMeta?.redirect,
    component: () => import("/vercel/path0/pages/about.vue").then(m => m.default || m)
  },
  {
    name: countdownvtqFImsxdOMeta?.name ?? "countdown",
    path: countdownvtqFImsxdOMeta?.path ?? "/countdown",
    meta: countdownvtqFImsxdOMeta || {},
    alias: countdownvtqFImsxdOMeta?.alias || [],
    redirect: countdownvtqFImsxdOMeta?.redirect,
    component: () => import("/vercel/path0/pages/countdown.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: mentions_45legalesaI7UrXH82KMeta?.name ?? "mentions-legales",
    path: mentions_45legalesaI7UrXH82KMeta?.path ?? "/mentions-legales",
    meta: mentions_45legalesaI7UrXH82KMeta || {},
    alias: mentions_45legalesaI7UrXH82KMeta?.alias || [],
    redirect: mentions_45legalesaI7UrXH82KMeta?.redirect,
    component: () => import("/vercel/path0/pages/mentions-legales.vue").then(m => m.default || m)
  },
  {
    name: testlK5QpQxp8eMeta?.name ?? "test",
    path: testlK5QpQxp8eMeta?.path ?? "/test",
    meta: testlK5QpQxp8eMeta || {},
    alias: testlK5QpQxp8eMeta?.alias || [],
    redirect: testlK5QpQxp8eMeta?.redirect,
    component: () => import("/vercel/path0/pages/test.vue").then(m => m.default || m)
  },
  {
    name: workssRZImgF9lMeta?.name ?? "work",
    path: workssRZImgF9lMeta?.path ?? "/work",
    meta: workssRZImgF9lMeta || {},
    alias: workssRZImgF9lMeta?.alias || [],
    redirect: workssRZImgF9lMeta?.redirect,
    component: () => import("/vercel/path0/pages/work.vue").then(m => m.default || m)
  }
]