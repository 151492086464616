import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.10.2_rollup@4.12.0_vite@5.1.3/node_modules/nuxt/dist/pages/runtime/validate.js";
import check_45mentions_45legales_45global from "/vercel/path0/middleware/check-mentions-legales.global.js";
import check_45talents_45section_45global from "/vercel/path0/middleware/check-talents-section.global.js";
import countdown_45global from "/vercel/path0/middleware/countdown.global.js";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.10.2_rollup@4.12.0_vite@5.1.3/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  check_45mentions_45legales_45global,
  check_45talents_45section_45global,
  countdown_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "first-visit": () => import("/vercel/path0/middleware/first-visit.js"),
  session: () => import("/vercel/path0/middleware/session.js")
}